import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/index/index.module').then( m => m.IndexPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'progress',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'index',
    loadChildren: () => import('./pages/index/index.module').then( m => m.IndexPageModule)
  },
  {
    path: 'tickets',
    loadChildren: () => import('./pages/tickets/tickets.module').then( m => m.TicketsPageModule)
  },
  {
    path: 'ticket-manual',
    loadChildren: () => import('./pages/ticket-manual/ticket-manual.module').then( m => m.TicketManualPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'product-details',
    loadChildren: () => import('./pages/product-details/product-details.module').then( m => m.ProductDetailsPageModule)
  },
  {
    path: 'recycling',
    loadChildren: () => import('./pages/recycling/recycling.module').then( m => m.RecyclingPageModule)
  },
  {
    path: 'modal-register',
    loadChildren: () => import('./pages/modal-register/modal-register.module').then( m => m.ModalRegisterPageModule)
  },
  {
    path: 'modal-congrats',
    loadChildren: () => import('./pages/modal-congrats/modal-congrats.module').then( m => m.ModalCongratsPageModule)
  },
  {
    path: 'recycling-map',
    loadChildren: () => import('./pages/recycling-map/recycling-map.module').then( m => m.RecyclingMapPageModule)
  },
  {
    path: 'how-works',
    loadChildren: () => import('./pages/how-works/how-works.module').then( m => m.HowWorksPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'redeem-points',
    loadChildren: () => import('./pages/redeem-points/redeem-points.module').then( m => m.RedeemPointsPageModule)
  },
  {
    path: 'scan',
    loadChildren: () => import('./pages/scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'license',
    loadChildren: () => import('./pages/license/license.module').then( m => m.LicensePageModule)
  },
  {
    path: 'act-for-food',
    loadChildren: () => import('./pages/act-for-food/act-for-food.module').then( m => m.ActForFoodPageModule)
  },
  {
    path: 'amount',
    loadChildren: () => import('./pages/amount/amount.module').then( m => m.AmountPageModule)
  },
  {
    path: 'marcas',
    loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsPageModule)
  },
  {
    path: 'brands',
    redirectTo: 'marcas',
    pathMatch: 'full'
  },
  {
    path: '**', redirectTo: '/'
  },
  {
    path: 'modal-separate',
    loadChildren: () => import('./pages/modal-separate/modal-separate.module').then( m => m.ModalSeparatePageModule)
  },
  {
    path: 'modal-location',
    loadChildren: () => import('./pages/modal-location/modal-location.module').then( m => m.ModalLocationPageModule)
  },
  {
    path: 'modal-featured-product',
    loadChildren: () => import('./pages/modal-featured-product/modal-featured-product.module').then( m => m.ModalFeaturedProductPageModule)
  },
  {
    path: 'modal-credence-treatment',
    loadChildren: () => import('./pages/modal-credence-treatment/modal-credence-treatment.module').then( m => m.ModalCredenceTreatmentPageModule)
  },
  {
    path: 'modal-credence-control',
    loadChildren: () => import('./pages/modal-credence-control/modal-credence-control.module').then( m => m.ModalCredenceControlPageModule)
  },
  {
    path: 'modal-email',
    loadChildren: () => import('./pages/modal-email/modal-email.module').then( m => m.ModalEmailPageModule)
  },
  {
    path: 'consent',
    loadChildren: () => import('./pages/consent/consent.module').then( m => m.ConsentPageModule)
  }
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
